/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

const Bases = () => {

  const fontSize = {
    fontSize: '13px'
  }

  return (
    <div className='container'>
      <h3>BASES DE LA CAMPAÑA DE DINAMIZACIÓN DEL COMERCIO LOCAL DE CARTAGENA “CONFÍA EN TU COMERCIO DE PROXIMIDAD, PUEDES GANAR EL COCHE”</h3>
      <p style={fontSize}>
        Los presentes términos y condiciones se encuentran a disposición de los usuarios a través de las webs <a href="http://coec.es/">www.coec.es</a> y <a href="https://www.cartagena.es/">www.ayto-cartagena.es</a> en ellas se recogen las condiciones de la campaña.
      </p>
      <h4>OBJETIVO Y ÁMBITO TERRITORIAL</h4>
      <p style={fontSize}>
        El sorteo <strong>“Confía en tu comercio de proximidad, puedes ganar el coche”</strong> (en adelante, “el sorteo”) tiene como objetivo la dinamización del comercio tradicional instalado en el término municipal de Cartagena.
      </p>
      <h4>PARTICIPANTES Y DURACIÓN</h4>
      <p style={fontSize}>
        Podrán participar en este sorteo todas aquellas personas que hayan realizado una compra en cualquier pequeño comercio del término municipal de Cartagena, siempre que la compra esté acreditada por un ticket de caja o factura (garantías fiscales), y se haya realizado entre el 17 de diciembre de 2021 y el 17 de enero de 2022, ambos inclusive, hasta las 23:59 del día 17 de enero de 2022. Siendo el sorteo el día 21 de enero de 2022 a las 12’00 h. ante Notario.
      </p>
      <p style={fontSize}>
        No podrán participar en este sorteo aquellos tickets de compras realizadas en supermercados, farmacias, estancos, comercios instalados en grandes superficies y grandes superficies.
      </p>
      <p style={fontSize}>
        Para adquirir la condición de participante será necesario:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;1. Haber realizado una compra, que debe estar acreditada mediante ticket o factura, en cualquier comercio tradicional del término municipal de Cartagena.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;2. Dar de alta el ticket y los datos como cliente participante en la aplicación web diseñada para esta campaña “Confía en tu comercio de proximidad, puedes ganar el coche”.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;3. El/La ganador/a deberá presentar el ticket original a la hora de recoger el COCHE.
      </p>
      <h3>PREMIO</h3>
      <p style={fontSize}>
        El premio de este sorteo será un coche modelo Dacia Sandero Comfort.
      </p>
      <p style={fontSize}>
        El sorteo se llevará a cabo en el transcurso del programa GACETA FM de la cadena radiofónica RADIO GACETA y ante Notario.
      </p>
      <p style={fontSize}>
        El Notario pulsará “botón digital de sorteo” incluido en la aplicación web diseñada para esta campaña. En caso de no ser localizado/a el/la agraciado/a de forma inmediata mediante llamada telefónica (cuatro tonos de llamada), el Notario volverá a pulsar el botón digital y procederá de igual modo.
      </p>
      <p style={fontSize}>
        El Notario podrá pulsar y repetir este protocolo hasta en nueve ocasiones.
      </p>
      <p style={fontSize}>
        El/La ganador/a del coche será aquella persona cuyo nombre haya sido el seleccionado mediante la aplicación web ante Notario, y deberá aportar y mostrar el ticket original de compra.
      </p>
      <p style={fontSize}>
        El/La ganador/a podrá renunciar al mismo, pero en ningún caso podrá canjearlo por otro distinto, ni por su valor en dinero, de conformidad con los previsto en las presentes bases. Si el/la ganador/a renunciara al premio del coche, automáticamente pasaría a ser el/la ganador/a el suplente número 1 y así sucesivamente.
      </p>
      <h4>ACEPTACIÓN DE LAS BASES</h4>
      <p style={fontSize}>
        La participación en el sorteo <strong>“Confía en tu comercio de proximidad, puedes ganar el coche”</strong>, supone la aceptación íntegra de las presentes bases y la sumisión expresa de las decisiones interpretativas que de las mismas efectúe COEC.
      </p>
      <h4>POLÍTICA DE PROTECCIÓN DE DATOS</h4>
      <p style={fontSize}>
        Sus datos personales serán usados para nuestra relación y poder prestarle nuestros servicios. Dichos datos son necesarios para poder relacionarnos con usted, lo que nos permite el uso de su información personal dentro de la legalidad. Asimismo, también pueden ser usados para otras actividades, como enviarle publicidad o promocionar nuestras actividades.
      </p>
      <p style={fontSize}>
        Sólo el personal de nuestra entidad que esté debidamente autorizado podrá tener conocimiento de la información que le pedimos. Asimismo, podrán tener conocimiento de su información aquellas entidades que necesiten tener acceso a la misma para que podamos prestarle nuestros servicios. Igualmente, tendrán conocimiento de su información aquellas entidades públicas o privadas a las cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna ley.
      </p>
      <p style={fontSize}>
        Conservaremos sus datos durante nuestra relación y mientras nos obliguen las leyes. Una vez finalizados los plazos legales aplicables, procederemos a eliminarlos de forma segura.
      </p>
      <p style={fontSize}>
        En cualquier momento puede dirigirse a nosotros para saber qué información tenemos sobre usted, rectificarla si fuese incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de que ello sea legalmente posible. También tiene derecho a solicitar el traspaso de su información a otra entidad (portabilidad). Para solicitar alguno de estos derechos, deberá realizar una solicitud escrita a nuestra dirección, junto con una fotocopia de su DNI, para poder identificarle:
      </p>
      <p style={fontSize}>
        CONFEDERACIÓN COMARCAL DE ORGANIZACIONES EMPRESARIALES DE CARTAGENA (COEC)
        C/ CARLOS III, 1, CP 30201, CARTAGENA (Murcia)
      </p>
      <p style={fontSize}>
        En caso de que entienda que sus derechos han sido desatendidos por nuestra entidad, puede formular una reclamación en la Agencia Española de Protección de Datos ( <a href="https://www.aepd.es/es">www.agpd.es</a>).
      </p>
      <p style={fontSize}>
        Existe una versión ampliada de esta información a su disposición tanto en nuestras oficinas como en nuestra página web.
      </p>
      <h4>FISCALIDAD</h4>
      <p style={fontSize}>
        La cumplimentación de los datos personales y fiscales del ganador necesario para la entrega del premio tiene carácter obligatorio, de forma que la ausencia de cualquiera de dichos datos en caso de
        ser necesarios supondrá la pérdida del derecho al premio obtenido. Los gastos de matriculación e impuestos están incluidos en el premio.
      </p>
      <h4>RECLAMACIONES</h4>
      <p style={fontSize}>
        El período de reclamación del presente sorteo finaliza transcurridos 15 días naturales desde la celebración de este.
      </p>
      <h4>MODIFICACIÓN DE LAS BASES</h4>
      <p style={fontSize}>
        COEC se reserva el derecho de modificar las presentes bases si fuera considerado necesario para la mejora y correcto desarrollo del sorteo.
      </p>
      <h4>CANCELACIÓN Y MODIFICACIÓN DE LAS CONDICIONES DE LA PROMOCIÓN</h4>
      <p style={fontSize}>
        Siempre que exista una causa justificada o de fuerza mayor debidamente motivada, COEC se reserva el derecho a cancelar, aplazar, suspender y/o modificar la celebración del presente sorteo. En tal caso, no se compromete a la asignación del premio.
      </p>
      <p style={fontSize}>
        Cualquier incidencia que pudiera surgir y que no se encuentre reflejada en las presentes bases será resuelta por COEC según su criterio, sin que quepa reclamación alguna.
      </p>
      <h4>LEGISLACIÓN APLICABLE</h4>
      <p style={fontSize}>
        La presente promoción se rige por la legislación española vigente.
      </p>

    </div>
  )
}

export default Bases
