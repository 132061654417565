import React, {useContext, useRef, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './form.scss';
import AuthContext from '../store/auth-context';
import Icon from '@mdi/react';
import {mdiLoading} from '@mdi/js';

const modal = withReactContent(Swal);

const Form = () => {
    //Variables
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [cif, setCif] = useState('');
    const [number, setNumber] = useState('');
    const [date, setDate] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState();
    const [errorCity, setErrorCity] = useState(false);
    const [errorCif, setErrorCif] = useState(false);
    const [errorPrice, setErrorPrice] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState();
    const [sending, setSending] = useState(false);

    //Variable para el formData del formulario
    const form = useRef(null);

    if (!authContext.user) {
        return <Navigate to="/iniciar-sesion"/>
    }

    const handleCif = (e) => {
        if (e.target.value) {
            if (!e.target.value.match(/^[a-zA-Z0-9]{9}$/)) {
                setErrorCif(true)
            } else {
                setErrorCif(false)
            }
        }
    }

    const handleCity = (e) => {
      if (e.target.value !== ''){
        setErrorCity(false)
      } else {
        setErrorCity(true)
      }
    }

    const handlePrice = (e) => {
        let value = e.target.value;
        if (value === '') {
            setErrorPrice(true);
            return;
        }
        const parts = value.toString().split('.');
        if (parts[1] !== undefined && parts[1].length > 2) {
            value = parseFloat(value.toString()).toFixed(2).replace('.', ',');
            setPrice(value);
            return;
        }
        setErrorPrice(false);
    }

    // Llamada doble onChange campo imagen
    const saveTicket = (e) => {
        setSelectedTicket(e.target.files[0])
        const ticket = URL.createObjectURL(e.target.files[0])
        setImage(ticket)
    }

    //Repetir foto

    const repeatPhoto = () => {
        setSelectedTicket('')
        setImage('')
    }

    // Validación
    const onSubmit = (e) => {
        e.preventDefault();
        if (sending) {
            return;
        }

        const formData = new FormData(form.current);
        formData.append('image', selectedTicket);
        setSending(true);
        fetch(process.env.REACT_APP_API_BASE_URL + '/save-ticket', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authContext.user.token}`,
                'App-Guard': 'customers'
            },
            body: formData
        })
          .then((res) => {
              if (res.ok){
                  modal.fire({
                      title: 'Ticket guardado',
                      text: '¡Suerte! Y recuerda: NO TIRES EL TICKET',
                      type: 'success'
                  });
                  setSending(false);
                  navigate('/');
              } else {
                  modal.fire({
                      title: 'Oh, vaya. Algo ha salido mal',
                      text: 'Inténtalo de nuevo',
                      icon: 'error'
                  });
                  setSending(false);
              }
          })
    }

    const disabled = !name || !city || !cif || !number || !date || !price || !image;

    return (
      <div className="ticket-form">
          <form
            className="add-form card card--semitransparent"
            ref={form}
            onSubmit={onSubmit}
            encType="multipart/form-data">
              <div className="card-body">
                  <h2 className="form-title">Introduce los datos del ticket</h2>
                  <div className="form-group mb-3">
                      <label htmlFor="shop-name">Nombre del comercio</label>
                      <input
                        className="form-control"
                        id="shop-name"
                        type="text"
                        placeholder="Añade el nombre del comercio"
                        name="name"
                        value={name}
                        maxLength="255"
                        onChange={(e) => setName(e.target.value)} />
                      <small className={`form-text text-muted`}>Añade el nombre como aparece en el ticket.</small>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="shop-name">Localidad</label>
                      <select
                        className="form-control"
                        id="city"
                        name='city'
                        placeholder='Seleccione la localidad'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        onBlur={handleCity}>
                          <option value=''>SELECCIONE UNA LOCALIDAD</option>
                          <option value='Alumbres'>Alumbres</option>
                          <option value='Atamira'>Atamira</option>
                          <option value='Bª Concepción'>Bª Concepción</option>
                          <option value='Barranco Borricen'>Barranco Borricen</option>
                          <option value='Bda. California'>Bda. California</option>
                          <option value='Bda. Cuatro Santos'>Bda. Cuatro Santos</option>
                          <option value='Bda. Hipanoamérica'>Bda. Hipanoamérica</option>
                          <option value='Bda. San Cristóbal'>Bda. San Cristóbal</option>
                          <option value='Bda. San Ginés'>Bda. San Ginés</option>
                          <option value='Bda. Santiago'>Bda. Santiago</option>
                          <option value='Bda. Villalba'>Bda. Villalba</option>
                          <option value='Bda. Virgen Caridad'>Bda. Virgen Caridad</option>
                          <option value='Beal'>Beal</option>
                          <option value='Bº Peral'>Bº Peral</option>
                          <option value='Cabo de Palos'>Cabo de Palos</option>
                          <option value='Cala Flores'>Cala Flores</option>
                          <option value='Cala Reona'>Cala Reona</option>
                          <option value='Campillo de Adentro'>Campillo de Adentro</option>
                          <option value='Canteras'>Canteras</option>
                          <option value='Cartagena'>Cartagena</option>
                          <option value='Casas de Sevilla'>Casas de Sevilla</option>
                          <option value='Casas de Tallante'>Casas de Tallante</option>
                          <option value='Casas del Molino'>Casas del Molino</option>
                          <option value='Central Térmica'>Central Térmica</option>
                          <option value='Cobaticas'>Cobaticas</option>
                          <option value='Collado de Tallante'>Collado de Tallante</option>
                          <option value='Cuesta Blanca Abajo'>Cuesta Blanca Abajo</option>
                          <option value='Cuesta Blanca de Arriba'>Cuesta Blanca de Arriba</option>
                          <option value='El Albujón'>El Albujón</option>
                          <option value='El Algar'>El Algar</option>
                          <option value='El Cañar'>El Cañar</option>
                          <option value='El Carmolí'>El Carmolí</option>
                          <option value='El Estrecho de San Ginés'>El Estrecho de San Ginés</option>
                          <option value='El Ferriol'>El Ferriol</option>
                          <option value='El Gorguel'>El Gorguel</option>
                          <option value='El Higueral'>El Higueral</option>
                          <option value='El Mojón'>El Mojón</option>
                          <option value='El Palmero'>El Palmero</option>
                          <option value='El Plan'>El Plan</option>
                          <option value='El Porche'>El Porche</option>
                          <option value='El Portús'>El Portús</option>
                          <option value='El Rosalar'>El Rosalar</option>
                          <option value='El Sabinar'>El Sabinar</option>
                          <option value='Ensanche-Almarjal'>Ensanche-Almarjal</option>
                          <option value='Ermita de Tallante'>Ermita de Tallante</option>
                          <option value='Ermita St. Bárbara'>Ermita St. Bárbara</option>
                          <option value='Escombreras'>Escombreras</option>
                          <option value='Esparragueral'>Esparragueral</option>
                          <option value='Fuente Amarga'>Fuente Amarga</option>
                          <option value='Galifa'>Galifa</option>
                          <option value='Isla Plana'>Isla Plana</option>
                          <option value='Islas Menores'>Islas Menores</option>
                          <option value='La Algameca'>La Algameca</option>
                          <option value='La Aljorra'>La Aljorra</option>
                          <option value='La Asomada'>La Asomada</option>
                          <option value='La Azohía'>La Azohía</option>
                          <option value='La Baña'>La Baña</option>
                          <option value='La Chapineta'>La Chapineta</option>
                          <option value='La Corona'>La Corona</option>
                          <option value='La Guía'>La Guía</option>
                          <option value='La Loma'>La Loma</option>
                          <option value='La Magdalena'>La Magdalena</option>
                          <option value='La Manchica'>La Manchica</option>
                          <option value='La Manga del Mar Menor'>La Manga del Mar Menor</option>
                          <option value='La Mina'>La Mina</option>
                          <option value='La Palma'>La Palma</option>
                          <option value='La Piqueta'>La Piqueta</option>
                          <option value='La Puebla'>La Puebla</option>
                          <option value='La Rambla'>La Rambla</option>
                          <option value='La Vaguada'>La Vaguada</option>
                          <option value='La Vereda'>La Vereda</option>
                          <option value='Las Barracas'>Las Barracas</option>
                          <option value='Las Casas'>Las Casas</option>
                          <option value='Las Casicas'>Las Casicas</option>
                          <option value='Las Lomas'>'Las Lomas</option>
                          <option value='Llano del Beal'>Llano del Beal</option>
                          <option value='Lo Batumo'>Lo Batumo</option>
                          <option value='Lo Campano'>Lo Campano</option>
                          <option value='Lo Campero'>Lo Campero</option>
                          <option value='Lo Tacón'>Lo Tacón</option>
                          <option value='Los Álamos'>Los Álamos</option>
                          <option value='Los Aparecida'>Los Aparecida</option>
                          <option value='Los Arrayos'>Los Arrayos</option>
                          <option value='Los Balanzas'>Los Balanzas</option>
                          <option value='Los Barberos'>Los Barberos</option>
                          <option value='Los Barreros'>Los Barreros</option>
                          <option value='Los Beatos'>Los Beatos</option>
                          <option value='Los Belones'>Los Belones</option>
                          <option value='Los Camachos'>Los Camachos</option>
                          <option value='Los Cañavates'>Los Cañavates</option>
                          <option value='Los Carrascosas'>Los Carrascosas</option>
                          <option value='Los Carriones'>Los Carriones</option>
                          <option value='Los Castillejos'>Los Castillejos</option>
                          <option value='Los Conesas'>Los Conesas</option>
                          <option value='Los Díaz'>Los Díaz</option>
                          <option value='Los Dolores'>Los Dolores</option>
                          <option value='Los Escabeas'>Los Escabeas</option>
                          <option value='Los Flores'>Los Flores</option>
                          <option value='Los Fuentes'>Los Fuentes</option>
                          <option value='Los Gabatos'>Los Gabatos</option>
                          <option value='Los Gallos'>Los Gallos</option>
                          <option value='Los Garcías'>Los Garcías</option>
                          <option value='Los Jorqueras'>Los Jorqueras</option>
                          <option value='Los Madriles'>Los Madriles</option>
                          <option value='Los Mateos'>Los Mateos</option>
                          <option value='Los Médicos'>Los Médicos</option>
                          <option value='Los Navarros'>Los Navarros</option>
                          <option value='Los Navarros Bajos'>Los Navarros Bajos</option>
                          <option value='Los Nicolases'>Los Nicolases</option>
                          <option value='Los Nietos'>Los Nietos</option>
                          <option value='Los Nietos Viejos'>Los Nietos Viejos</option>
                          <option value='Los Patojos'>Los Patojos</option>
                          <option value='Los Pérez'>Los Pérez</option>
                          <option value='Los Pérez de Arriba'>Los Pérez de Arriba</option>
                          <option value='Los Piñuelas'>Los Piñuelas</option>
                          <option value='Los Puches'>Los Puches</option>
                          <option value='Los Rizos'>Los Rizos</option>
                          <option value='Los Roses'>Los Roses</option>
                          <option value='Los Rosiques'>Los Rosiques</option>
                          <option value='Los Ruices'>Los Ruices</option>
                          <option value='Los Salazares'>Los Salazares</option>
                          <option value='Los Sanchez'>Los Sanchez</option>
                          <option value='Los Segados'>Los Segados</option>
                          <option value='Los Sominotes'>Los Sominotes</option>
                          <option value='Los Urrutias'>Los Urrutias</option>
                          <option value='Los Ventorrillos'>Los Ventorrillos</option>
                          <option value='Los Vidales'>Los Vidales</option>
                          <option value='Mar de Cristal'>Mar de Cristal</option>
                          <option value='Media Legua'>Media Legua</option>
                          <option value='Miranda'>Miranda</option>
                          <option value='Molino Derribado'>Molino Derribado</option>
                          <option value='Molinos Gallegos'>Molinos Gallegos</option>
                          <option value='Molinos Marfagones'>Molinos Marfagones</option>
                          <option value='Palma de Arriba'>Palma de Arriba</option>
                          <option value='Peñas Blancas'>Peñas Blancas</option>
                          <option value='Perín'>Perín</option>
                          <option value='Playa Honda'>Playa Honda</option>
                          <option value='Playa Paraíso'>Playa Paraíso</option>
                          <option value='Plgo. Santa Ana'>Plgo. Santa Ana</option>
                          <option value='Poblado Marina'>Poblado Marina</option>
                          <option value='Pozo Estrecho'>Pozo Estrecho</option>
                          <option value='Pozo Los Palos'>Pozo Los Palos</option>
                          <option value='Puertos Sª Bárbara'>Puertos Sª Bárbara</option>
                          <option value='Punta Brava'>Punta Brava</option>
                          <option value='Rincón de Tallante'>Rincón de Tallante</option>
                          <option value='Rio Seco'>Rio Seco</option>
                          <option value='San Antonio Abad'>San Antonio Abad</option>
                          <option value='San Ginés'>San Ginés</option>
                          <option value='San Ginés de la Jara'>San Ginés de la Jara</option>
                          <option value='San Isidro'>San Isidro</option>
                          <option value='San Jose Obrero'>San Jose Obrero</option>
                          <option value='Santa Ana'>Santa Ana</option>
                          <option value='Santa Lucía'>Santa Lucía</option>
                          <option value='Tentegorra'>Tentegorra</option>
                          <option value='Torre Calín'>Torre Calín</option>
                          <option value='Torreciega'>Torreciega</option>
                          <option value='Trapajuar'>Trapajuar</option>
                          <option value='Urb. Alcalde Cartagena'>Urb. Alcalde Cartagena</option>
                          <option value='Urb. Campo Golf'>Urb. Campo Golf</option>
                          <option value='Urb. Campomar'>Urb. Campomar</option>
                          <option value='Urb. Castillitos'>Urb. Castillitos</option>
                          <option value='Urb. Estrella de Mar'>Urb. Estrella de Mar</option>
                          <option value='Urb. La Fuensanta'>Urb. La Fuensanta</option>
                          <option value='Urb. Media Sala'>Urb. Media Sala</option>
                          <option value='Urb. Mediterráneo'>Urb. Mediterráneo</option>
                          <option value='Urb. Nueva Cartagena'>Urb. Nueva Cartagena</option>
                          <option value='Urb. Roche Alto'>Urb. Roche Alto</option>
                          <option value='Valdelentisco'>Valdelentisco</option>
                          <option value='Venta del Señorito'>Venta del Señorito</option>
                          <option value='Vereda de Roche'>Vereda de Roche</option>
                          <option value='Vista Alegre'>Vista Alegre</option>
                      </select>   
                      <small className={`form-text ${errorCity ? 'text-danger' : 'text-muted'}`}>Seleccione de la lista la localidad donde realizó la compra</small>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="shop-id">CIF del Comercio</label>
                      <input
                        className="form-control"
                        id="shop-id"
                        type="text"
                        placeholder="Añade el CIF del comercio"
                        name="cif"
                        value={cif}
                        maxLength="255"
                        onBlur={handleCif}
                        onChange={(e) => setCif(e.target.value)}
                      />
                      <small className={`form-text ${errorCif ? 'text-danger' : 'text-muted'}`}>El CIF debe ser de nueve caracteres sin símbolos. Ej: B12345678, 12345678e...</small>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="ticket-id">Número de ticket</label>
                      <input
                        className="form-control"
                        id="ticket-id"
                        type="text"
                        placeholder="Añade el número de ticket"
                        value={number}
                        maxLength="255"
                        name="number"
                        onChange={(e) => setNumber(e.target.value)}/>
                      <small className="form-text text-muted">Añade el número como aparece en el ticket</small>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="ticket-date">Fecha de compra</label>
                      <input
                        className="form-control"
                        id="ticket-date"
                        type="date"
                        name="date"
                        value={date}
                        placeholder="dd/mm/aaaa"
                        onChange={(e) => setDate(e.target.value)}/>
                      <small className="form-text text-muted">Selecciona la fecha en la que se realizó la compra</small>
                  </div>
                  <div className="form-group mb-3">
                      <label htmlFor="ticket-price">Valor total de la compra</label>
                      <div className="input-group">
                          <input
                            className="form-control"
                            id="ticket-price"
                            type="number"
                            name="price"
                            placeholder="0,00"
                            value={price}
                            onBlur={handlePrice}
                            step="0.1"
                            max="999999"
                            onChange={(e) => setPrice(e.target.value)}/>
                          <div className="input-group-append">
                              <span className="input-group-text">€</span>
                          </div>
                      </div>
                      <small className={`form-text ${errorPrice ? 'text-danger' : 'text-muted'}`}>Indica el valor total de la compra. Sólo números, máximo 2 decimales, coma como separador de decimales y sin separador de miles. Ej: "42,99", "120", "10,2"</small>
                  </div>
                  {selectedTicket ? (
                    <div className="d-flex flex-column align-items-center">
                        <img
                          src={URL.createObjectURL(selectedTicket)}
                          className="d-block mb-2 border"
                          style={{width: '100%', maxWidth: '300px'}}
                          alt=""
                        />
                        <button
                          className="btn btn-secondary mx-auto mt-2"
                          type="button"
                          onClick={repeatPhoto}
                        >¿Quiere repetir la foto?</button>
                    </div>
                  ) : (
                    <div className="form-group">
                        <label>Captura del ticket</label>
                        <input
                          className="form-control"
                          id="image"
                          type="file"
                          accept="image/*"
                          capture="environment"
                          name="image"
                          onChange={saveTicket}
                        />
                    </div>
                  )}

                  <div className="w-full text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg mx-auto mt-3"
                        disabled={disabled || sending}
                      >{sending ? (
                        <Icon
                          path={mdiLoading}
                          size={1}
                          color="white"
                          spin={1}
                        />
                      ) : 'Enviar ticket'}</button>
                      {disabled && <p className="text-danger mt-3 mb-0">Rellena todos los campos y selecciona una imagen.</p>}
                  </div>
              </div>
          </form>
      </div>
    )
}

export default Form
