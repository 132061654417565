import React from 'react'

const BasesPrivacy = () => {

  const fontSize = {
    fontSize: '13px'
  }

  return (
    <div className='container'>
      <h3>BASES DE LA CAMPAÑA DE DINAMIZACIÓN DEL COMERCIO LOCAL DE CARTAGENA “CONFÍA EN TU COMERCIO DE PROXIMIDAD, PUEDES GANAR EL COCHE”</h3>
      <p style={fontSize}>
        Los presentes términos y condiciones se encuentran a disposición de los usuarios a través de las webs <a href="http://coec.es/">www.coec.es</a> y <a href="https://www.cartagena.es/">www.ayto-cartagena.es</a> en ellas se recogen las condiciones de la campaña.
      </p>
      <h4>OBJETIVO Y ÁMBITO TERRITORIAL</h4>
      <p style={fontSize}>
        El sorteo <strong>“Confía en tu comercio de proximidad, puedes ganar el coche”</strong> (en adelante, “el sorteo”) tiene como objetivo la dinamización del comercio tradicional instalado en el término municipal de Cartagena.
      </p>
      <h4>PARTICIPANTES Y DURACIÓN</h4>
      <p style={fontSize}>
        Podrán participar en este sorteo todas aquellas personas que hayan realizado una compra en cualquier pequeño comercio del término municipal de Cartagena, siempre que la compra esté acreditada por un ticket de caja o factura (garantías fiscales), y se haya realizado entre el 17 de diciembre de 2021 y el 17 de enero de 2022, ambos inclusive, hasta las 23:59 del día 17 de enero de 2022. Siendo el sorteo el día 21 de enero de 2022 a las 12’00 h. ante Notario.
      </p>
      <p style={fontSize}>
        No podrán participar en este sorteo aquellos tickets de compras realizadas en supermercados, farmacias, estancos, comercios instalados en grandes superficies y grandes superficies.
      </p>
      <p style={fontSize}>
        Para adquirir la condición de participante será necesario:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;1. Haber realizado una compra, que debe estar acreditada mediante ticket o factura, en cualquier comercio tradicional del término municipal de Cartagena.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;2. Dar de alta el ticket y los datos como cliente participante en la aplicación web diseñada para esta campaña “Confía en tu comercio de proximidad, puedes ganar el coche”.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;3. El/La ganador/a deberá presentar el ticket original a la hora de recoger el COCHE.
      </p>
      <h3>PREMIO</h3>
      <p style={fontSize}>
        El premio de este sorteo será un coche modelo Dacia Sandero Comfort.
      </p>
      <p style={fontSize}>
        El sorteo se llevará a cabo en el transcurso del programa GACETA FM de la cadena radiofónica RADIO GACETA y ante Notario.
      </p>
      <p style={fontSize}>
        El Notario pulsará “botón digital de sorteo” incluido en la aplicación web diseñada para esta campaña. En caso de no ser localizado/a el/la agraciado/a de forma inmediata mediante llamada telefónica (cuatro tonos de llamada), el Notario volverá a pulsar el botón digital y procederá de igual modo.
      </p>
      <p style={fontSize}>
        El Notario podrá pulsar y repetir este protocolo hasta en nueve ocasiones.
      </p>
      <p style={fontSize}>
        El/La ganador/a del coche será aquella persona cuyo nombre haya sido el seleccionado mediante la aplicación web ante Notario, y deberá aportar y mostrar el ticket original de compra.
      </p>
      <p style={fontSize}>
        El/La ganador/a podrá renunciar al mismo, pero en ningún caso podrá canjearlo por otro distinto, ni por su valor en dinero, de conformidad con los previsto en las presentes bases. Si el/la ganador/a renunciara al premio del coche, automáticamente pasaría a ser el/la ganador/a el suplente número 1 y así sucesivamente.
      </p>
      <h4>ACEPTACIÓN DE LAS BASES</h4>
      <p style={fontSize}>
        La participación en el sorteo <strong>“Confía en tu comercio de proximidad, puedes ganar el coche”</strong>, supone la aceptación íntegra de las presentes bases y la sumisión expresa de las decisiones interpretativas que de las mismas efectúe COEC.
      </p>
      <h4>POLÍTICA DE PROTECCIÓN DE DATOS</h4>
      <p style={fontSize}>
        Sus datos personales serán usados para nuestra relación y poder prestarle nuestros servicios. Dichos datos son necesarios para poder relacionarnos con usted, lo que nos permite el uso de su información personal dentro de la legalidad. Asimismo, también pueden ser usados para otras actividades, como enviarle publicidad o promocionar nuestras actividades.
      </p>
      <p style={fontSize}>
        Sólo el personal de nuestra entidad que esté debidamente autorizado podrá tener conocimiento de la información que le pedimos. Asimismo, podrán tener conocimiento de su información aquellas entidades que necesiten tener acceso a la misma para que podamos prestarle nuestros servicios. Igualmente, tendrán conocimiento de su información aquellas entidades públicas o privadas a las cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna ley.
      </p>
      <p style={fontSize}>
        Conservaremos sus datos durante nuestra relación y mientras nos obliguen las leyes. Una vez finalizados los plazos legales aplicables, procederemos a eliminarlos de forma segura.
      </p>
      <p style={fontSize}>
        En cualquier momento puede dirigirse a nosotros para saber qué información tenemos sobre usted, rectificarla si fuese incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de que ello sea legalmente posible. También tiene derecho a solicitar el traspaso de su información a otra entidad (portabilidad). Para solicitar alguno de estos derechos, deberá realizar una solicitud escrita a nuestra dirección, junto con una fotocopia de su DNI, para poder identificarle:
      </p>
      <p style={fontSize}>
        CONFEDERACIÓN COMARCAL DE ORGANIZACIONES EMPRESARIALES DE CARTAGENA (COEC)
        C/ CARLOS III, 1, CP 30201, CARTAGENA (Murcia)
      </p>
      <p style={fontSize}>
        En caso de que entienda que sus derechos han sido desatendidos por nuestra entidad, puede formular una reclamación en la Agencia Española de Protección de Datos ( <a href="https://www.aepd.es/es">www.agpd.es</a>).
      </p>
      <p style={fontSize}>
        Existe una versión ampliada de esta información a su disposición tanto en nuestras oficinas como en nuestra página web.
      </p>
      <h4>FISCALIDAD</h4>
      <p style={fontSize}>
        La cumplimentación de los datos personales y fiscales del ganador necesario para la entrega del premio tiene carácter obligatorio, de forma que la ausencia de cualquiera de dichos datos en caso de
        ser necesarios supondrá la pérdida del derecho al premio obtenido. Los gastos de matriculación e impuestos están incluidos en el premio.
      </p>
      <h4>RECLAMACIONES</h4>
      <p style={fontSize}>
        El período de reclamación del presente sorteo finaliza transcurridos 15 días naturales desde la celebración de este.
      </p>
      <h4>MODIFICACIÓN DE LAS BASES</h4>
      <p style={fontSize}>
        COEC se reserva el derecho de modificar las presentes bases si fuera considerado necesario para la mejora y correcto desarrollo del sorteo.
      </p>
      <h4>CANCELACIÓN Y MODIFICACIÓN DE LAS CONDICIONES DE LA PROMOCIÓN</h4>
      <p style={fontSize}>
        Siempre que exista una causa justificada o de fuerza mayor debidamente motivada, COEC se reserva el derecho a cancelar, aplazar, suspender y/o modificar la celebración del presente sorteo. En tal caso, no se compromete a la asignación del premio.
      </p>
      <p style={fontSize}>
        Cualquier incidencia que pudiera surgir y que no se encuentre reflejada en las presentes bases será resuelta por COEC según su criterio, sin que quepa reclamación alguna.
      </p>
      <h4>LEGISLACIÓN APLICABLE</h4>
      <p style={fontSize}>
        La presente promoción se rige por la legislación española vigente.
      </p>

      <br />
      <br />

      <h2>SUS DATOS SEGUROS</h2>
      <h3>Información en cumplimiento de la normativa de protección de datos personales</h3>
      <p style={fontSize}>
        En Europa y en España existen normas de protección de datos pensadas para proteger su información personal de obligado cumplimiento para nuestra entidad.
      </p>
      <p style={fontSize}>
        Por ello, es muy importante para nosotros que entienda perfectamente qué vamos a hacer con los datos personales que le pedimos.
      </p>
      <p style={fontSize}>
        Así, seremos transparentes y le daremos el control de sus datos, con un lenguaje sencillo y opciones claras que le permitirán decidir qué haremos con su información personal.
      </p>
      <p style={fontSize}>
        Por favor, si una vez leída la presente información le queda alguna duda, no dude en preguntarnos.
      </p>
      <p style={fontSize}>
        Muchas gracias por su colaboración.
      </p>
      <h4>¿QUIÉNES SOMOS?</h4>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestra denominación: <strong>CONFEDERACIÓN COMARCAL DE ORGANIZACIONES EMPRESARIALES DE CARTAGENA (COEC)</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestro CIF / NIF: <strong>G30612527</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestra actividad principal: <strong>Confederación Empresarial</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestra dirección: <strong>C/ CARLOS III, 1, CP 30201, CARTAGENA (Murcia)</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestro teléfono de contacto: <strong>968505650</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestra dirección de correo electrónico de contacto: <strong>coec@coec.es</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Nuestra página web: <strong>www.coec.es</strong>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Para su confianza y seguridad, le informamos que somos una entidad inscrita en el siguiente Registro Mercantil /Registro Público:
      </p>
      <p style={fontSize}>
        Estamos a su disposición, no dude en contactar con nosotros.
      </p>
      <h3>¿PARA QUÉ VAMOS A USAR SUS DATOS?</h3>
      <p style={fontSize}>
        Con carácter general, sus datos personales serán usados para poder relacionarnos con usted. En este caso, para celebrar el sorteo al que se ha inscrito.
      </p>
      <p style={fontSize}>
        En caso de usar sus datos para otras actividades, como enviarle publicidad o promocionar nuestras actividades, le solicitaremos el correspondiente permiso.
      </p>
      <h4>¿POR QUÉ NECESITAMOS USAR SUS DATOS?</h4>
      <p style={fontSize}>
        Sus datos personales son necesarios para poder relacionarnos con usted y poder prestarle nuestros servicios, en este caso, para poder celebrar el sorteo de manera correcta. Los datos solicitados en la aplicación, son los datos necesarios para podernos comunicar con usted en caso de resultar ganador del mismo.
      </p>
      <h4>¿QUIÉN VA A CONOCER LA INFORMACIÓN QUE LE PEDIMOS?</h4>
      <p style={fontSize}>
        Con carácter general, sólo el personal de nuestra entidad que esté debidamente autorizado podrá tener conocimiento de la información que le pedimos.
      </p>
      <p style={fontSize}>
        De igual modo, podrán tener conocimiento de su información personal aquellas entidades que necesiten tener acceso a la misma para que podamos prestarle nuestros servicios. Así por ejemplo, es posible que sus datos se tengan que ceder al concesionario o a Tráfico.
      </p>
      <p style={fontSize}>
        Asimismo, tendrán conocimiento de su información aquellas entidades públicas o privadas a las cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna ley.
      </p>
      <p style={fontSize}>
        En el caso de que, al margen de los supuestos comentados, necesitemos dar a conocer su información personal a otras entidades, le solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir a este respecto.
      </p>
      <h4>¿CÓMO VAMOS A PROTEGER SUS DATOS?</h4>
      <p style={fontSize}>
        Protegeremos sus datos con medidas de seguridad eficaces en función de los riesgos que conlleve el uso de su información.
      </p>
      <p style={fontSize}>
        Para ello, nuestra entidad ha aprobado una Política de Protección de Datos y se realizan controles y auditorías anuales para verificar que sus datos personales están seguros en todo momento.
      </p>
      <h4>¿ENVIAREMOS SUS DATOS A OTROS PAÍSES?</h4>
      <p style={fontSize}>
        En el mundo hay países que son seguros para sus datos y otros que no lo son tanto. Así por ejemplo, la Unión Europea es un entorno seguro para sus datos. Nuestra política es no enviar su información personal a ningún país que no sea seguro desde el punto de vista de la protección de sus datos.
      </p>
      <p style={fontSize}>
        En el caso de que, con motivo de prestarle el servicio, sea imprescindible enviar sus datos a un país que no sea tan seguro como España, siempre le solicitaremos previamente su permiso y aplicaremos medidas de seguridad eficaces que reduzcan los riesgos del envío de su información personal a otro país.
      </p>
      <h4>¿DURANTE CUÁNTO TIEMPO VAMOS A CONSERVAR SUS DATOS?</h4>
      <p style={fontSize}>
        Conservaremos sus datos durante nuestra relación y mientras nos obliguen las leyes. Una vez finalizados los plazos legales aplicables, procederemos a eliminarlos de forma segura y respetuosa con el medio ambiente.
      </p>
      <h4>¿CUÁLES SON SUS DERECHOS DE PROTECCIÓN DE DATOS?</h4>
      <p style={fontSize}>
        En cualquier momento puede dirigirse a nosotros para saber qué información tenemos sobre usted, rectificarla si fuese incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de que ello sea legalmente posible.
      </p>
      <p style={fontSize}>
        También tiene derecho a solicitar el traspaso de su información a otra entidad. Este derecho se llama “portabilidad” y puede ser útil en determinadas situaciones.
      </p>
      <p style={fontSize}>
        Para solicitar alguno de estos derechos, deberá realizar una solicitud escrita a nuestra dirección, junto con una fotocopia de su DNI, para poder identificarle.
      </p>
      <p style={fontSize}>
        En las oficinas de nuestra entidad disponemos de formularios específicos para solicitar dichos derechos y le ofrecemos nuestra ayuda para su cumplimentación.
      </p>
      <p style={fontSize}>
        Para saber más sobre sus derechos de protección de datos, puede consultar la página web de la Agencia Española de Protección de Datos (<a href="https://www.aepd.es/es">www.aepd.es</a>).
      </p>
      <h4>¿PUEDE RETIRAR SU CONSENTIMIENTO SI CAMBIA DE OPINIÓN EN UN MOMENTO POSTERIOR?</h4>
      <p style={fontSize}>
        Usted puede retirar su consentimiento si cambia de opinión sobre el uso de sus datos en cualquier momento.
      </p>
      <p style={fontSize}>
        Así por ejemplo, si usted en su día estuvo interesado/a en recibir publicidad de nuestros productos o servicios, pero ya no desea recibir más publicidad, puede hacérnoslo constar a través del formulario de oposición al tratamiento disponible en las oficinas de nuestra entidad.
      </p>
      <h4>EN CASO DE QUE ENTIENDA QUE SUS DERECHOS HAN SIDO DESATENDIDOS, ¿DÓNDE PUEDE FORMULAR UNA RECLAMACIÓN?</h4>
      <p style={fontSize}>
        En caso de que entienda que sus derechos han sido desatendidos por nuestra entidad, puede formular una reclamación en la Agencia Española de Protección de Datos, a través de alguno de los medios siguientes:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Sede electrónica: <a href="https://www.aepd.es/es">www.aepd.es</a>
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Dirección postal:<br></br>
        &nbsp;&nbsp;&nbsp;&nbsp;Agencia Española de Protección de Datos<br></br> 
        &nbsp;&nbsp;&nbsp;&nbsp;C/ Jorge Juan, 6<br></br> 
        &nbsp;&nbsp;&nbsp;&nbsp;28001-Madrid
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Vía telefónica:<br></br>
        &nbsp;&nbsp;&nbsp;&nbsp;Telf. 901 100 099<br></br>
        &nbsp;&nbsp;&nbsp;&nbsp;Telf. 91 266 35 17
      </p>
      <p style={fontSize}>
        Formular una reclamación en la Agencia Española de Protección de Datos no conlleva ningún coste y no es necesaria la asistencia de abogado ni procurador.
      </p>
      <h4>¿ELABORAMOS PERFILES SOBRE USTED?</h4>
      <p style={fontSize}>
        Nuestra política es no elaborar perfiles sobre los usuarios de nuestros servicios.
      </p>
      <p style={fontSize}>
        No obstante, pueden existir situaciones en las que, con fines de prestación del servicio, comerciales o de otro tipo, necesitemos elaborar perfiles de información sobre usted. Un ejemplo pudiera ser la utilización de su historial de compras o servicios para poder ofrecerle productos o servicios adaptados a sus gustos o necesidades.
      </p>
      <p style={fontSize}>
        En tal caso, aplicaremos medidas de seguridad eficaces que protejan su información en todo momento de personas no autorizadas que pretendan utilizarla en su propio beneficio.
      </p>
      <h4>¿USAREMOS SUS DATOS PARA OTROS FINES?</h4>
      <p style={fontSize}>
        Nuestra política es no usar sus datos para otras finalidades distintas a las que le hemos explicado. Si, no obstante, necesitásemos usar sus datos para actividades distintas, siempre le solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir al respecto.
      </p>

    </div>
    
  )
}

export default BasesPrivacy
