import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../store/auth-context';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import './login.scss';
import Icon from '@mdi/react';
import {mdiEye, mdiEyeOff, mdiLoading} from '@mdi/js';

const modal = withReactContent(Swal);

const Login = () => {
    const authContext = useContext(AuthContext);

    const [sending, setSending] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Mostrar-Ocultar contraseña
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    // Validación
    const onSubmit = (e) => {
        e.preventDefault();

        if (sending) {
            return;
        }

        if(!email) {
            modal.fire({
                title: 'Por favor, añade un email válido',
                icon: 'error'
            });
            return;
        } else if (!password) {
            modal.fire({
                title: 'Por favor, introduce una contraseña',
                icon: 'error'
            });
            return;
        }
        setSending(true);
        authContext.onLogin({ email, password })
          .catch((err) => {
              modal.fire({
                  title: 'Usuario o contraseña no válidos.',
                  icon: 'error'
              });
          })
          .finally((err) => {
              console.log(err);
              setSending(false);
          });
    }

    return (
        <div>
            <div className='login-page'>
                <div className="card card--semitransparent">
                    <div className="card-body">
                        <p className="fs-4 fw-bold mb-4">Inicia sesión con tu cuenta</p>
                        <form className='add-form' onSubmit={onSubmit}>
                            <div className='form-group mb-3'>
                                <label>Email</label>
                                <input
                                  type="email"
                                  id="email"
                                  className="form-control"
                                  placeholder="Email con el que te registraste"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div className='form-group mb-3'>
                                <label>Contraseña</label>
                                <div className="input-group">
                                    <input
                                      type={showPassword ? 'text' : 'password'}
                                      id="password"
                                      className="form-control"
                                      value={password}
                                      placeholder="Escribe tu contraseña"
                                      onChange={(e) => setPassword(e.target.value)}/>
                                    <button
                                      type="button"
                                      className="btn btn-outline-dark"
                                      onClick={togglePassword}>
                                        <Icon path={showPassword ? mdiEyeOff : mdiEye}
                                              size={1}
                                              horizontal
                                              vertical/>
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center mt-4">
                                <button
                                  type="submit"
                                  disabled={!email || !password || sending}
                                  className="btn btn-primary btn-lg"
                                >{sending ? (
                                  <Icon
                                    path={mdiLoading}
                                    size={1}
                                    color="white"
                                    spin={1}
                                  />
                                ) : 'Iniciar sesión'}</button>
                                <Link className='btn btn-sm mt-2' to="/has-olvidado-contrasena">¿Has olvidado la contraseña?</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login


