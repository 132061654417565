import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const modal = withReactContent(Swal);

const AuthContext = React.createContext({
  isLoading: true,
  isLoggedIn: false,
  user: null,
  onLogout: () => {},
  onLogin: ({email, password}) => {},
});

export const AuthContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = cookies.get('rtoken');
    if (token) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh-access-token`, {
        method: 'POST',
        body: JSON.stringify({token: token}),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'App-Guard': 'customers'
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw Error(res.status);
          }
          return res.json();
        })
        .then((res) => {
          if (cookies.get('cookies')) {
            const date = new Date();
            cookies.set('rtoken', res.refresh_token, {expire: date.setFullYear(date.getFullYear() + 1)});
          }
          setUser(res);
          setIsLoggedIn(true);
          setIsLoading(false);
        })
        .catch((err) => {
          cookies.remove('rtoken');
          setUser(null);
          setIsLoggedIn(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  // Función de login
  const loginHandler = (data) => {
    return fetch(process.env.REACT_APP_API_BASE_URL + '/auth', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'App-Guard': 'customers'
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw Error('Invalid credentials');
        }
        return res.json();
      })
      .then((res) => {
        if (cookies.get('cookies')) {
          const date = new Date();
          cookies.set('rtoken', res.refresh_token, {expire: date.setFullYear(date.getFullYear() + 1)});
        }
        setUser(res);
        setIsLoggedIn(true);
        navigate('/');
      });
  }

  const logoutHandler = async () => {
    const req = await fetch(process.env.REACT_APP_API_BASE_URL + '/logout', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
        'App-Guard': 'customers'
      }
    })

    if(req.ok){
      cookies.remove('rtoken');
      setIsLoggedIn(false);
      setUser(null);
      navigate('/');
      // history.push('/');
    } else {
      modal.fire({
        title: 'No hemos podido cerrar sesión. Prueba de nuevo más tarde.',
        icon: 'error'
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading: isLoading,
        isLoggedIn: isLoggedIn,
        user: user,
        onLogin: loginHandler,
        onLogout: logoutHandler,
      }}>{props.children}</AuthContext.Provider>
  );
}

export default AuthContext;
