import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AuthContext from '../store/auth-context';
import Icon from '@mdi/react';
import { mdiEyeOff, mdiEye } from '@mdi/js';
import './register.scss';

const modal = withReactContent(Swal);

const Register = () => {
    const authContext = useContext(AuthContext);

    const [sending, setSending] = useState(false);
    const [name, setName] = useState('');
    const [dni, setDni] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // Mostrar-Ocultar contraseña
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword((last) => !last);
    };

    //Aceptar términos y verificar validación
    const [isDisabled, setIsDisabled] = useState(true);
    const [terms, setTerms] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [publi, setPubli] = useState(false);

    const canBeSubmitted = () => {
        return name &&
        dni &&
        email &&
        phone &&
        password &&
        confirmPassword &&
        terms &&
        checkImage &&
        publi ? (setIsDisabled(true)) : (setIsDisabled(false));
    };

    const onCheckboxClickTerms = () => {
        setTerms(!terms);
        return canBeSubmitted();
    };

    const onCheckboxClickImage = () => {
        setCheckImage(!checkImage);

    };
    const onCheckboxClickPubli = () => {
        setPubli(!publi);
    };

    const [errorName, setErrorName] = useState(false);

    const handleName = (e) => {
        if (e.target.value) {
            if (!e.target.value.match(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)) {
                setErrorName(true)
            } else {
                setErrorName(false)
            }
        }
    }

    const [errorDni, setErrorDni] = useState(false);

    const handleDni = (e) => {
        if(e.target.value === ''){
            setErrorDni(false)
        }
        if (e.target.value) {
            if (!e.target.value.match(/^[a-zA-Z]{1}\d{7}[a-zA-Z]{1}|\d{8}[a-zA-Z]{1}$/)) {
                setErrorDni(true);
            } else {
                setErrorDni(false)
            }
        }
    }

    const [errorEmail, setErrorEmail] = useState(false);

    const handleEmail = (e) => {
        if(e.target.value === ''){
            setErrorEmail(false)
        }
        if (e.target.value) {
            if (!e.target.value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                setErrorEmail(true);
            } else {
                setErrorEmail(false)
            }
        }
    }

    const [errorPhone, setErrorPhone] = useState(false);

    const handlePhone = (e) => {
        if(e.target.value === ''){
            setErrorPhone(false)
        }
        if (e.target.value) {
            if (!e.target.value.match(/^[0-9]{9}$/)) {
                setErrorPhone(true);
            } else {
                setErrorPhone(false)
            }
        }
    }

    const [errorPassword, setErrorPassword] = useState(false);

    const handlePassword = (e) => {
        if(e.target.value === ''){
            setErrorPassword(false)
        }
        if (e.target.value) {
            if (!e.target.value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)) {
                setErrorPassword(true);
            } else {
                setErrorPassword(false)
            }
        }
    }

    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(false);

    const handlePasswordConfirm = (e) => {
        if(e.target.value === ''){
            setErrorPasswordConfirm(false)
        }
        if (e.target.value !== password) {
            setErrorPasswordConfirm(true);
        } else {
            setErrorPasswordConfirm(false)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (sending) {
            return;
        }
        setSending(true);
        
        return fetch(process.env.REACT_APP_API_BASE_URL + '/register', {
            method: 'POST',
            body: JSON.stringify({ name, dni, email, phone, password, checkImage, publi }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'App-Guard': 'customers'
            }
        })
          .then((res) => res.json())
          .then((data) => {
              console.log(data)
              setSending(false);
              if (data.errors){
                  if(data.errors.email){
                      modal.fire({
                          title: 'El email ya ha sido registrado. Prueba con otro.',
                          icon: 'error'
                      });
                      return;
                  }
                  if(data.errors.dni){
                      modal.fire({
                          title: 'El DNI ya ha sido registrado. Comprueba que esté bien.',
                          icon: 'error'
                      });
                      return;
                  }
                  if(data.errors.phone){
                      modal.fire({
                          title: 'El teléfono ya ha sido registrado. Comprueba que esté bien.',
                          icon: 'error'
                      });
                      return;
                  }
              }
              if(data.id){
                  authContext.onLogin({email: email, password: password});
              }
          })
            .catch((err) => setSending(false));
    }

    return (
      <div>
          <div className='register-page'>
              <div className="card card--semitransparent">
                  <div className="card-body">
                      <form className='add-form' onSubmit={onSubmit}>
                          <p className="fs-4 fw-bold mb-4">Regístrate</p>
                          <div className="form-group mb-3">
                              <label>Nombre Completo</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder='Nombre completo'
                                value={name}
                                maxLength="255"
                                onBlur={handleName}
                                onChange={(e) => setName(e.target.value)}
                              />
                              {errorName ? (
                                <small className="d-block form-text text-danger">Nombre no válido.</small>
                              ) : (
                                <small className="d-block form-text text-muted">El nombre sólo puede contener letras y espacios.</small>
                              )}
                          </div>
                          <div className="form-group mb-3">
                              <label>DNI</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder='12345678A'
                                value={dni}
                                maxLength="255"
                                onBlur={handleDni}
                                onChange={(e) => setDni(e.target.value)}
                              />
                              {errorDni && (
                                <small className="d-block form-text text-danger">DNI no válido. El formato debe ser 12345678A o A1234567A.</small>
                              )}
                          </div>
                          <div className="form-group mb-3">
                              <label>Correo electrónico</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder='Correo electrónico'
                                value={email}
                                maxLength="255"
                                onBlur={handleEmail}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errorEmail && (
                                <small className="form-text text-danger">El correo no tiene un formato válido. Comprueba que lo has escrito correctamente.</small>
                              )}
                          </div>
                          <div className="form-group mb-3">
                              <label>Teléfono</label>
                              <input
                                type="phone"
                                className="form-control"
                                placeholder='Número de teléfono'
                                value={phone}
                                maxLength="255"
                                onBlur={handlePhone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                              {errorPhone ? (
                                <small className="d-block form-text text-danger">El teléfono no tiene un formato válido.</small>
                              ) : (
                                <small className="d-block form-text text-muted">9 dígitos sin espacios.</small>
                              )}
                          </div>
                          <div className="form-group mb-3">
                              <label>Contraseña</label>
                              <div className="input-group">
                                  <input
                                    type={ showPassword ? "text" : "password" }
                                    className="form-control"
                                    name="password"
                                    value={password}
                                    maxLength="255"
                                    placeholder="Introduce una contraseña"
                                    onBlur={handlePassword}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={togglePassword}>
                                      <Icon path={showPassword ? mdiEyeOff : mdiEye}
                                            size={1}
                                            horizontal
                                            vertical/>
                                  </button>
                              </div>
                              {errorPassword && (
                                <small className="d-block form-text text-danger">Contraseña no válida.</small>
                              )}
                              <small className="d-block form-text text-muted">8 caracteres o más + 1 letra mayúscula + 1 letra minúscula + 1 número</small>
                          </div>
                          <div className="form-group mb-3">
                              <label>Repite la contraseña</label>
                              <div className="input-group">
                                  <input
                                    type={ showPassword ? "text" : "password" }
                                    className="form-control"
                                    name='confirmpassword'
                                    maxLength="255"
                                    placeholder="Repite la contraseña"
                                    onBlur={handlePasswordConfirm}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={togglePassword}>
                                      <Icon path={showPassword ? mdiEyeOff : mdiEye}
                                            size={1}
                                            horizontal
                                            vertical/>
                                  </button>
                              </div>
                              {errorPasswordConfirm && (
                                <small className="d-block form-text text-danger">Las contraseñas no coinciden.</small>
                              )}
                          </div>
                          
                            <Link
                                className='btn btn-secondary btn-sm my-2'
                                to='/bases'
                                target='_blank'>Condiciones y Bases
                            </Link>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Link
                                className='btn btn-secondary btn-sm my-2'
                                to='/politica-privacidad'
                                target='_blank'>Política de Privacidad
                            </Link>

                          <div className="form-check">
                              <input
                                type="checkbox"
                                id="checkImage"
                                name="checkImage"
                                className="form-check-input"
                                value={checkImage}
                                onClick={onCheckboxClickImage}/>
                              <label className="form-check-label" htmlFor="checkImage">
                                  Consiento la publicación de mi imagen (si soy el ganador) en Facebook y otros medios similares para difundir las actividades de su entidad
                              </label>
                          </div>

                          <div className="form-check">
                              <input
                                type="checkbox"
                                id="publi"
                                name="publi"
                                className="form-check-input"
                                value={publi}
                                onClick={onCheckboxClickPubli}/>
                              <label className="form-check-label" htmlFor="publi">
                                  Consiento el uso de mis datos personales para recibir comunicaciones comerciales y futuros sorteos de su entidad.
                              </label>
                          </div>

                          <div className="form-check">
                              <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                className="form-check-input"
                                value={terms}
                                onClick={onCheckboxClickTerms}/>
                              <label className="form-check-label" htmlFor="terms">
                                  Acepto las bases y la política de privacidad
                              </label>
                          </div>

                          <div className="d-flex flex-column align-items-center mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg mx-auto"
                                disabled={isDisabled}
                              >Registrarse</button>
                              {isDisabled && (
                                <p className="mt-2 mb-0 text-danger">El formulario no es válido. Comprueba que has rellenado todos los campos correctamente.</p>
                              )}
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default Register
