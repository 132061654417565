import React, {useContext, useState} from 'react';

import './header.scss';
import concLogo from '../images/logos/area_comcentrico.png';
import aytoLogo from '../images/logos/ayto_cartagena.png';
import coecLogo from '../images/logos/coecnorm_black.png';
import AuthContext from '../store/auth-context';
import CustomLink from './CustomLink';
import Icon from '@mdi/react';
import {mdiMenu} from '@mdi/js';

const Header = () => {
  const authContext = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="header">
      <div className="logos">
        <div className="logo">
          <img src={coecLogo} alt="COEC"/>
        </div>
        <div className="logo logo--ayto">
          <img src={aytoLogo} alt="Ayuntamiento Cartagena"/>
        </div>
        <div className="logo">
          <img src={concLogo} alt="Comercio concéntrico"/>
        </div>
      </div>
      <nav
        className={`menu ${showMenu ? 'show' : ''}`}
        onClick={() => setShowMenu((current) => !current)}
      >
        <CustomLink to="/">Inicio</CustomLink>
        {authContext.isLoggedIn ? (
          <>
          <CustomLink to="/guia-de-utilizacion">Guía de utilización</CustomLink>
          <button className="nav-btn btn btn-outline-primary" onClick={authContext.onLogout}>Cerrar sesión</button>
          </>
          
        ) : (
          <>
            <CustomLink to="/guia-de-utilizacion">Guía de utilización</CustomLink>
            <CustomLink to="/crear-cuenta">Crear cuenta</CustomLink>
            <CustomLink to="/iniciar-sesion">Iniciar sesión</CustomLink>            
          </>
        )}
      </nav>
      <button
        type="button"
        id="menu-toggler"
        onClick={() => setShowMenu((current) => !current)}
        className={`btn btn-sm btn-outline-dark bg-white`}>
        <Icon
          path={mdiMenu}
          size={1}
        />
      </button>
    </div>
  )
}

export default Header
