import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import AuthContext from '../store/auth-context';
import imgLoading from '../images/loading.gif';

const Tickets = () => {
  const [tickets, setTickets] = useState(null);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const env = process.env;
  const fetchUrl = env.REACT_APP_API_BASE_URL;
  const user = authContext.user;
  const token = user.token;

  const fetchTickets = useCallback(async () => {
    const res = await fetch(fetchUrl + `/tickets/count`, {
      headers: {
        'Accept': 'application/json',
        'Acces-Control': 'allow-origin',
        'Authorization': `Bearer ${token}`,
        'App-Guard': 'customers'
      }
    });
    setIsLoading(false);

    return res.json();
  }, [fetchUrl, token]);

  useEffect(() => {
    fetchTickets().then((data) => setTickets(data.count));
  }, [fetchTickets]);

  return (
    <div className="my-4">
      {isLoading ? (
        <img src={imgLoading} alt="" className="mx-auto" height="80"/>
      ) : (tickets > 0 ? (
          <div>
            <p className="h3 mb-4">Tienes <b>{tickets}</b> {'participaci' + (tickets === 1 ? 'ón' : 'ones')}.</p>
            <p className="notranslate mb-0" translate="no">RECUERDA: ¡NO TIRES LOS TICKETS DESPUÉS DE VALIDARLOS!</p>
            <p>Tendrás que entregarlos en caso de resultar ganador/a.</p>
            <Link className='btn btn-primary btn-lg mt-5' to="/validar">Valida otro ticket</Link>
          </div>
        ) : (
          <div>
            <p className="h2 mb-5">Aún no tienes participaciones. ¡Date prisa! ¡El coche te espera!</p>
            <Link className='btn btn-primary btn-lg' to="/validar">VALIDAR TICKET</Link>
          </div>
        )
      )}
    </div>
  )
}


export default Tickets
