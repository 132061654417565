import React, {useContext, useState} from 'react';

import './App.scss';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Form from './components/Form';
import Tickets from './components/Tickets';
import Bases from './components/Bases';
import BasesPrivacy from './components/BasesPrivacy';
import Guide from './components/Guide';
import Privacy from './components/Privacy';
import Recover from './components/Recover';
import Reset from './components/ResetPassword';

import { createHashHistory } from 'history';

import {Link, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import AuthContext from './store/auth-context';
import CookiesPolicy from './components/CookiesPolicy';
import imgLoading from './images/loading.gif';
import Cookies from 'universal-cookie';

export const history = createHashHistory();

const cookies = new Cookies();

function App() {
  const authContext = useContext(AuthContext);
  const [closeCookies, setCloseCookies] = useState(false);

  const hasCookies = cookies.get('cookies');

  const acceptCookies = () => {
    const date = new Date();
    cookies.set('cookies', '1', {expire: date.setFullYear(date.getFullYear() + 1)});
    setCloseCookies(true);
  }

  return (
    <>
      {!authContext.isLoading ? (
        <>
          <Header />
          <div className="App">
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/tickets' element={<Tickets />} />
              <Route path='/guia-de-utilizacion' element={<Guide />} />
              <Route path='/crear-cuenta' element={<Register />} />
              <Route path='/iniciar-sesion' element={<Login />} />
              <Route path='/validar' element={<Form />} />
              <Route path='/bases' element={<Bases />} />
              <Route path='/bases-privacidad' element={<BasesPrivacy />} />
              <Route path='/politica-privacidad' element={<Privacy />} />
              <Route path='/politica-cookies' element={<CookiesPolicy />} />
              <Route path='/has-olvidado-contrasena' element={<Recover />} />
              <Route path='/restablecer-contrasena/:token' element={<Reset />} />
            </Routes>
            {!hasCookies && !closeCookies && (
              <div className="cookies_modal-backdrop">
                <div className="cookies_modal">
                  <div className="cookies_title">INFORMACIÓN BÁSICA SOBRE COOKIES</div>
                  <div className="cookies_content">
                    <p>Bienvenida/o a la información básica sobre las cookies de la página web responsabilidad de la entidad:</p>
                    <p>CONFEDERACIÓN COMARCAL DE ORGANIZACIONES EMPRESARIALES DE CARTAGENA (COEC)</p>
                    <p>Una cookie o galleta informática es un pequeño archivo de información que se guarda en tu ordenador, “smartphone” o tableta cada vez que visitas nuestra página web. Algunas cookies son nuestras y otras pertenecen a empresas externas que prestan servicios para nuestra página web.</p>
                    <p>Las cookies técnicas son necesarias para que nuestra página web pueda funcionar, no necesitan de tu autorización y son las únicas que tenemos activadas por defecto.</p>
                    <p>Si quieres más información, consulta la <Link to="/politica-cookies" onClick={() => setCloseCookies(true)}>POLÍTICA DE COOKIES</Link> de nuestra página web.</p>
                  </div>
                  <div className="cookies_actions">
                    <button
                      type="button"
                      onClick={acceptCookies.bind(this)}
                      className="btn btn-primary d-block text-center">ACEPTAR</button>
                    <button
                      type="button"
                      onClick={() => setCloseCookies(true)}
                      className="btn btn-secondary d-block text-center">CERRAR</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="App">
          <div className="text-center">
            <img src={imgLoading} alt="" className="mx-auto" height="80"/>
          </div>
        </div>
      )}
    </>
  )
}

export default App;
