import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import Tickets from './Tickets'
import './home.scss';
import AuthContext from '../store/auth-context';
import imgLoading from '../images/loading.gif';

const Home = () => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true)
  const [available, setAvailable] = useState(false);

  const token = authContext.user ? authContext.user.token : null;

  useEffect(() => {
    if (token) {
      fetch(process.env.REACT_APP_API_BASE_URL + '/raffle/available', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'App-Guard': 'customers'
        }
      })
        .then((res) => res.json())
        .then((res) => setAvailable(res.available))
        .catch(() => setAvailable(false))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [token]);


  return (
    <div>
      <div className='index-menu'>
        <div className="home mt-5">
          {authContext.isLoggedIn && authContext.user ? (
            <>
              <div>
                <h2 className="h2">¡Hola, {authContext.user.name}!</h2>
                {loading ? (
                  <img src={imgLoading} alt="" className="mx-auto" height="80"/>
                ) : (available ? (
                    <Tickets />
                  ) : (
                    <div className="mb-4 p-3">
                      <p className="fw-bold fs-4">No hay ningún sorteo disponible.</p>
                    </div>
                  )
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mb-4 bg-transparent p-3">
                <p className="fw-bold fs-4">¡Hola! Crea una cuenta para subir un ticket y participar.</p>
                <Link className='btn btn-primary mt-1' to="/crear-cuenta">Registrarse</Link>
              </div>
              <div className="mb-4 bg-transparent p-3">
                <div className="d-flex flex-column align-items-center">
                  <p className="fw-bold fs-4">Si ya tienes una cuenta, ¡entra con tus datos y sigue participando!</p>
                  <Link className='btn btn-primary mt-1' to="/iniciar-sesion">Iniciar sesión</Link>
                  <Link className='btn btn-sm mt-2' to="/has-olvidado-contrasena">¿Has olvidado tu contraseña?</Link>
                </div>
              </div>
            </>
          )}
          {available && (
            <div className="d-flex flex-column align-items-center justify-content-start mt-4">
              <Link className='btn btn-outline-primary btn-sm' to="/bases-privacidad">Bases y política de privacidad</Link>
            </div>
          )}
          <Link className='btn btn-outline-primary btn-sm mt-2' to="/politica-cookies">Política de cookies</Link>
        </div>
      </div>
    </div>
  )
}

export default Home
