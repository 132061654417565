/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

const Guide = () => {

  const fontSize = {
    fontSize: '13px'
  }

  return (
    <div className='container'>
      <br />
      <h3>GUÍA DE UTILIZACIÓN DE LA APLICACIÓN</h3>
      <br />
      <p style={fontSize}>
        Para facilitar la comprensión de esta guía, vamos a simular un turno de preguntas que cubra todos los aspectos del manejo de la aplicación. Al final de esta guía, usted será capaz de manejarse por ella con soltura y de introducir todos los tickets de compra que quiera. ¡Ya solo le quedará saber si resulta ganador!
      </p>
      <br />
      <h4><i>AUN NO ME HE REGISTRADO. ¿CÓMO LO HAGO?</i></h4>
      <br />
      <p style={fontSize}>
        El registro de nuevos usuarios en la aplicación es muy sencillo. Tan solo tendrá que entrar en <a href="https://coec.soporteunico.es/">www.coec.soporteunico.es</a> y pulsar el botón con el texto “registrarse”. Esta acción le llevará a un formulario que deberá rellenar con datos reales. 
      </p>
      <p style={fontSize}>
        Mientras introduce los datos en el formulario, si éstos son incorrectos, el recuadro tendrá un borde rojizo. ¡No se preocupe! Puede modificar la información para que sea correcta. Una vez que esté seguro/a de que está bien, puede pulsar en el siguiente campo (el borde rojizo del campo anterior desaparecerá).
      </p>
      <p style={fontSize}>
        Para introducir los datos sin generar errores, le dejamos unas pequeñas indicaciones:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>NOMBRE COMPLETO:</strong> introduzca su nombre tal cual aparece en su documento de identificación. Puede contener espacios y tildes, pero no números o símbolos.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>DNI:</strong> introduzca el número de su documento de identificación tan cual viene en el mismo. Es importante respetar el formato original para que el campo no genere error.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>CORREO ELECTRÓNICO:</strong> aquí deberá introducir una dirección de correo electrónico válida, ya que será imprescindible para poder reclamar el premio. Las direcciones de correo son únicas, por lo solo se pueden registrar una vez.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>TELÉFONO:</strong> introduzca su número de teléfono de contacto. Debe estar operativo, pues las comunicaciones referentes al sorteo se realizarán por este medio principalmente.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>CONTRASEÑA:</strong> para mayor seguridad de sus datos, la contraseña debe de cumplir con los siguientes requisitos:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Debe de tener seis caracteres o más de longitud.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Debe incluir al menos una letra minúscula.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Debe incluir al menos una letra mayúscula.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Debe incluir al menos un número.
      </p>
      <p style={fontSize}>
        Podrá combinar esos elementos de la forma que deseé. Por ejemplo:  <i>SORTEO1coche</i> (por motivos de seguridad, <strong>NO UTILICE ESTA CONTRASEÑA</strong>).
      </p>
      <p style={fontSize}>
        Para asegurarnos de que la contraseña introducida es la que desea, deberá repetirla en el siguiente campo exactamente igual (si lo desea, puede comprobar las contraseñas pulsando en el botón con el símbolo del ojo que hay a la derecha de cada campo, lo que mostrará el texto introducido). Sólo si ambas coinciden, se podrá finalizar el registro.
      </p>
      <p style={fontSize}>
      Después del formulario encontrará tres casillas de verificación.
      </p>
      <p style={fontSize}>
        En la primera de ellas, deberá indicar si quiere que se utilice su imagen para fines promocionales en las redes sociales y medios de comunicación. Este campo es opcional.
      </p>
      <p style={fontSize}>
        En la segunda, deberá indicar si consiente que su información sea almacenada de forma segura para poder comunicarle futuras campañas y promociones. Este campo es opcional.
      </p>
      <p style={fontSize}>
        La tercera casilla hace referencia a las bases del concurso y a la política de privacidad, las cuales puede leer accediendo a ellas mediante los enlaces ubicados en la propia aplicación. Si no está marcada, no podrá registrarse. Marcar esta casilla es indispensable según marca la ley.
      </p>
      <p style={fontSize}>
      Una vez que todo esté correcto, el botón de “registrarse” quedará activo y usted podrá mandar su solicitud. Si el proceso se ha realizado correctamente, la aplicación le avisará con un mensaje en pantalla.
      </p>
      <p style={fontSize}>
      ¡Enhorabuena! Ya puede empezar a participar.
      </p> 
      <br />
      <h3><i>YA ESTOY REGISTRADO. ¿CÓMO EMPIEZO A PARTICIPAR?</i></h3>
      <br />
      <p style={fontSize}>
        Una vez registrado, cada vez que entre en la aplicación se le mostrará un mensaje con información acerca del estado de su participación. Si ya ha validado tickets de compra, le indicaremos cuántas participaciones lleva. Si aún no lo ha hecho, ¡es el momento de empezar a participar! No se preocupe, el proceso es igual de sencillo que el del registro de usuarios.
      </p>
      <p style={fontSize}>
        Lo primero que hay que hacer es pulsar el botón con el texto “validar ticket”. Esto le llevará a un formulario muy parecido al del registro de usuario, y que además funciona igual; si un campo no está rellenado correctamente, su borde se tornará de color rojizo, indicando el fallo.
      </p>
      <p style={fontSize}>
        Repasemos qué son esos campos:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>NOMBRE DEL COMERCIO:</strong> hay que indicar en qué comercio se ha realizado la compra. 
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>LOCALIDAD:</strong> hay que indicar en qué localidad se encuentra el comercio donde se ha realizado la compra seleccionandola de la lista desplegable. 
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>C.I.F. DEL COMERCIO:</strong> es el identificador único del comercio. Esta información viene impresa en el ticket de compra. Es importante que la introduzca tal cuál aparezca en el ticket.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>NÚMERO DE TICKET:</strong> por temas de contabilidad, cada ticket es generado con un número único. Este número vendrá indicado en el ticket de compra. Es importante que lo introduzca en el formulario tal cual viene impreso.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>FECHA DE COMPRA:</strong> : al seleccionar este campo, se abrirá un calendario. Seleccione en él el día exacto de la compra. Si no lo recuerda, podrá comprobar la fecha directamente en el ticket. 
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>PRECIO TOTAL DE LA COMPRA:</strong> deberá indicar el valor total de la compra, la suma de todos los artículos que haya comprado y que aparezcan en el ticket. Esta información se facilita en el propio ticket. 
      </p>
      <p style={fontSize}>
      &nbsp;&nbsp;&nbsp;&nbsp;<strong>CAPTURA DEL TICKET:</strong> al pulsar en el botón, automáticamente se abrirá la cámara fotográfica de su dispositivo (si su dispositivo no tiene cámara, ¡no se preocupe! Más adelante cubriremos su caso). Esta acción NO borra los datos que ya haya rellenado en el formulario, así que esté tranquilo/a. 
      </p>
      <p style={fontSize}>
      &nbsp;&nbsp;&nbsp;&nbsp;Con la cámara deberá tomar una fotografía del ticket que esté validando. Debe de procurar que la fotografía sea nítida y que todos los datos se puedan leer correctamente. Una vez que haya hecho la fotografía, pulse el botón de verificación de la cámara (este botón variará según el dispositivo que esté usando) y automáticamente volverá al formulario, donde aparecerá una previsualización de la fotografía. Si no queda convencido/a de la fotografía, hemos habilitado un botón para que pueda hacer otra. Púlselo y repita los pasos hasta que la fotografía sea nítida y legible.
      </p>
      <p style={fontSize}>
      Si ha rellenado todos los campos y ha tomado la fotografía, pulse el botón con el texto “Enviar ticket”. ¡Enhorabuena! Habrá validado su primera participación (se le confirmará con un mensaje en pantalla).
      </p>
      <p style={fontSize}>
      Y recuerde:<strong> NO TIRE LOS TICKETS DESPUÉS DE VALIDARLOS EN LA APLICACIÓN.</strong> Serán requeridos por la organización del sorteo para comprobar su veracidad en caso de resultar ganador/a.
      </p>  
      <br />    
      <h4><i>MI DISPOSITIVO NO CUENTA CON CÁMARA FOTOGRÁFICA. ¿QUÉ PUEDO HACER?</i></h4>
      <br />
      <p style={fontSize}>
      En el supuesto de que usted no cuente con una cámara fotográfica, deberá de seguir los siguientes pasos:
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Hacer una fotografía del ticket en otro dispositivo. 
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Enviar esa fotografía a su dispositivo (por ejemplo, enviando un correo electrónico a su propia dirección con la fotografía dentro).
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Guardar la fotografía en su dispositivo.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;Rellenar el formulario de envío de la forma que se ha explicado más arriba, con una salvedad: cuando pulse en el botón de “Captura del Ticket”, en vez de la cámara fotográfica, se abrirá un selector de archivos. En él, deberá buscar la fotografía guardada en el paso anterior y seleccionarla.
      </p>
      <p style={fontSize}>
        &nbsp;&nbsp;&nbsp;&nbsp;El resto del proceso es igual; se mostrará una previsualización de la fotografía elegida y, si no fuese correcta, podrá volver a seleccionar otra. 
      </p>
      <p style={fontSize}>
      &nbsp;&nbsp;&nbsp;&nbsp;Validar el ticket.
      </p>
      <br />
      <h4><i>YA ESTOY REGISTRADO, PERO AL ABRIR LA APLICACIÓN NO ME APARECEN LAS PARTICIPACIONES QUE LLEVO. ¿CÓMO VUELVO A ENTRAR?</i></h4>
      <p style={fontSize}>
      <br />
      El proceso para entrar con sus datos no puede ser más sencillo. Tan solo debe pulsar el botón con el texto “Iniciar Sesión”. Esto le llevará a un formulario con dos únicos campos: uno para que introduzca su correo electrónico, y otro para que introduzca la contraseña que eligió al registrarse. Rellene esos campos, pulse el botón de enviar y su sesión se restaurará, mostrándole la información sobre sus participaciones. ¡Es así de sencillo!
      </p>
      <br />
      <h4><i>ME REGISTRÉ EN LA APLICACIÓN, PERO NO ME ACUERDO DE LA CONTRASEÑA. ¿QUÉ PUEDO HACER?</i></h4>
      <br />
      <p style={fontSize}>
      Si ha olvidado la contraseña con la que se registró en la aplicación, ¡no se preocupe! Tanto en la pantalla de inicio como en el formulario que aparece tras pulsar el botón “Iniciar Sesión”, hemos habilitado un enlace para que pueda solventar el problema. Lo único que debe hacer es pulsar en el texto “¿Has olvidado la contraseña?”. Esto le llevará a un formulario de un único campo donde deberá introducir el correo electrónico con el que se registró en la aplicación. Introdúzcalo y pulse “Enviar”. Si la dirección de correo electrónico es correcta y se encuentra en la base de datos de la aplicación, automáticamente se le enviará un correo electrónico con un enlace dentro. Deberá pulsarlo para continuar con el proceso. 
      </p>
      <p style={fontSize}>
      Lo que hacemos con este paso es verificar que realmente es usted quien está pidiendo contraseñas nuevas, y no alguien que ha puesto su dirección de correo electrónico.
      </p>
      <p style={fontSize}>
      ¿Y si no le llega ningún correo electrónico? Quizás el formato del correo electrónico sea correcto pero la dirección esté mal. En ese caso, al no coincidir ese correo que ninguno de nuestra base de datos, no hemos podido enviar el correo de confirmación. Vuelva a repetir el proceso e inténtelo de nuevo.
      </p>
      <p style={fontSize}>
      <strong>ES MUY IMPORTANTE QUE SE ASEGURE DE QUE LA DIRECCIÓN ESTÁ BIEN ANTES DE ENVIAR LA SOLICITUD. SI NO, NO PODREMOS ENVIARLE EL CORREO DE CONFIRMACIÓN.</strong>
      </p>
      <p style={fontSize}>
      ¿Y si todo ha salido bien y ya le ha llegado el correo de confirmación? Entonces pulse el enlace que contiene. Esto abrirá automáticamente la aplicación con un formulario de tres campos. 
      </p>
      <p style={fontSize}>
      En el primero de ellos deberá de introducir su correo electrónico de nuevo. Sabemos que puede parecer repetitivo, pero es por motivos de seguridad y protección de datos. En los campos siguientes, deberá introducir una contraseña NUEVA y verificarla, como hizo al registrarse por primera vez.
      </p>
      <p style={fontSize}>
      Una vez rellenados los campos, pulse el botón “Enviar Solicitud”. ¡Y listo! Si la información es correcta, se habrá cambiado su contraseña y podrá seguir utilizando la aplicación.
      </p>
      <p style={fontSize}>
      Si se produjese algún error, se le informaría mediante mensajes en pantalla, con información acerca de qué ha pasado y cómo solucionarlo. 
      </p>
      <br />
      <h4><i>NO ME GUSTA QUE MIS DATOS SE QUEDEN ACTIVOS EN EL DISPOSITIVO. ¿HAY ALGUNA MANERA DE EVITAR ESO?</i></h4>
      <br />
      <p style={fontSize}>
      Sí que la hay. Tan solo deberá dirigirse al menú de la parte superior derecha y pulsar en “Cerrar Sesión”. ¡Así de fácil! La aplicación volverá a la pantalla de inicio con las opciones de registrarse y de iniciar sesión. Para volver a entrar, tan solo haga lo indicado más arriba.
      </p>
      <p style={fontSize}>
      <strong>AVISO:</strong> cada vez que cierre sesión tendrá que introducir sus datos para volver a entrar.
      </p>
      <p style={fontSize}>
      <strong>NOTA: </strong>el menú superior derecho, según en el dispositivo en el que se encuentre, puede estar oculto tras un botón con tres líneas horizontales (ubicado también en la parte superior derecha). Pulse ese botón y le aparecerán las opciones.
      </p>
      <br />
      <h4><i>AL UTILIZAR LA APLICACIÓN ME APARECEN ERRORES. ¿QUÉ OCURRE?</i></h4>
      <br />
      <p style={fontSize}>
      Los errores que puedan producirse al rellenar cualquiera de los formularios se explicarán mediante mensajes en la pantalla, indicando su naturaleza y la forma de solucionarlos. Siga las instrucciones y asegúrese de que cualquier dato que aporte esté redactado correctamente.
      </p>
      <p style={fontSize}>
      En el supuesto de que se encontrase con problemas distintos, por favor, póngase en contacto con la organización del sorteo. Su caso se resolverá a la mayor brevedad posible.
      </p>
      <br />
      <h4><i>¿CÓMO SABRÉ SI HE GANADO EL SORTEO?</i></h4>
      <br />
      <p style={fontSize}>
      El sorteo se realizará ante notario y se difundirá en los medios indicados durante la campaña. Si no puede seguirlo, ¡no se preocupe! Nos pondremos en contacto con usted utilizando los datos facilitados en el registro de la aplicación. <strong>ES IMPORTANTE QUE ESOS DATOS SEAN REALES.</strong> 
      </p>
      <br />
      <p style={fontSize}>
      Y nada más. Como puede comprobar, el uso de la aplicación es sencillo y está preparada para indicar posibles incidencias a tiempo real, facilitando información para que usted solo tenga que registrarse, validar cuantos más tickets mejor, ¡y recibir un coche de regalo!
      </p>
      <br />
      <p style={fontSize}>
      Le deseamos que tenga toda la suerte del mundo. <strong>¡Y felices fiestas!</strong> 
      </p>
     

    </div>
  )
}

export default Guide
