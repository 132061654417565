import React, { useState } from 'react'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import './recover.scss';
import Icon from '@mdi/react';
import {mdiLoading} from '@mdi/js';

const modal = withReactContent(Swal);

const Recover = () => {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);

  const handleEmail = (e) => {
    setError(false);
    if(e.target.value === ''){
      setErrorEmail(false)
    }
    if (e.target.value) {
      if (!e.target.value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        setErrorEmail(true);
      } else {
        setErrorEmail(false)
      }
    }
  }
  const onSubmit = (e) => {
    e.preventDefault();

    if (sending) {
      return;
    }

    if(!email) {
      modal.fire({
        title: 'Por favor, escribe el email con el que te registraste',
        icon: 'error'
      });
      return;
    }
    setSending(true);
    fetch(process.env.REACT_APP_API_BASE_URL + '/auth/forgot-password', {
      method: 'POST',
      body: JSON.stringify({email: email}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'App-Guard': 'customers'
      }
    })
      .then((res) => {
        if (res.ok) {
          setError(false);
          setSuccess(true);
          setSending(false);
        } else {
          setSuccess(false)
          setError(true);
          setSending(false);
        }
      })
      .catch((err) => {
        setSuccess(false);
        setError(true);
        setSending(false);
      });
  }

  return (
    <div className='recover-page'>
      <div className="card card--semitransparent">
        <div className="card-body">
          <p className="fs-4 fw-bold mb-3">¿Has olvidado tu contraseña?</p>
          <p className="mb-3 text-muted">Escribe en este formulario el correo con el que te registraste. Cuando se envíe la solicitud deberías recibir un mensaje con un enlace para establecer una contraseña nueva.</p>
          {success && (
            <div className="card border-success mb-3">
              <div className="card-body">
                <p className="fw-bold fs-5 mb-0 text-success">Tu solicitud ha sido enviada.</p>
                <p className="mb-0 text-muted">Si tenemos una cuenta con esa dirección de correo recibirás en la bandeja de entrada un correo con indicaciones para establecer una contraseña nueva. Si no lo recibes, espera unos minutos y comprueba la bandeja de correo no deseado y que has escrito correctamente la dirección.</p>
                <div className="d-flex justify-content-end">
                  <button type="button" onClick={() => setSuccess(false)} className="mt-3 btn ms-auto">Cerrar</button>
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className="card border-danger mb-3">
              <div className="card-body">
                <p className="fw-bold fs-5 mb-0 text-danger">Ha ocurrido un error.</p>
                <p className="mb-0 text-muted">No hemos podido procesar tu solicitud. Inténtalo de nuevo en unos minutos.</p>
                <div className="d-flex justify-content-end">
                  <button type="button" onClick={() => setError(false)} className="mt-3 btn ms-auto">Cerrar</button>
                </div>
              </div>
            </div>
          )}
          <form className="add-form" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="recover-email">Correo electrónico</label>
              <input
                id="recover-email"
                type="email"
                autoComplete="email"
                placeholder="Escribe tu correo"
                className="form-control"
                value={email}
                onBlur={handleEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorEmail && (
                <div className="form-text text-danger">El correo no es válido. Comprueba que lo has escrito correctamente.</div>
              )}
            </div>
            <div className="d-flex flex-column align-items-center mt-4">
              <button
                type="submit"
                disabled={errorEmail || email === '' || sending}
                className="btn btn-lg btn-primary">
                {sending ? (
                  <Icon
                    path={mdiLoading}
                    size={1}
                    color="white"
                    spin={1}
                  />
                ) : 'Enviar'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Recover
