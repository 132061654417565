import * as React from "react";
import {
  Link,
  useMatch,
  useResolvedPath
} from "react-router-dom";

const CustomLink = ({ children, to, className, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className={`${className} btn ${match ? 'btn-primary' : ''}`}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
}

export default CustomLink;
