import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './reset-password.scss';
import Icon from '@mdi/react';
import {mdiEye, mdiEyeOff} from '@mdi/js';

const modal = withReactContent(Swal);

const Reset = () => {
    const navigate = useNavigate();

    //coger token
    const { token } = useParams();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    // Mostrar-Ocultar contraseña
    const [showPassword, setShowPassword] = useState(false);

    //Mostrar contraseña
    const togglePassword = () => {
        setShowPassword((last) => !last);
    };

    const checkDisabled = () => {
        setIsDisabled(!email || !password || !passwordConfirm || errorEmail || errorPassword || errorPasswordConfirm);
    }

    const handleEmail = (e) => {
        if(e.target.value === ''){
            setErrorEmail(false)
        }
        if (e.target.value) {
            const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!e.target.value.match(regExp)) {
                setErrorEmail(true);
            } else {
                setErrorEmail(false)
            }
        }
        checkDisabled();
    }

    const handlePassword = (e) => {
        if(e.target.value === ''){
            setErrorPassword(false)
        }
        if (e.target.value) {
            if (!e.target.value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)) {
                setErrorPassword(true);
            } else {
                setErrorPassword(false)
            }
        }
        checkDisabled();
    }

    const handlePasswordConfirm = (e) => {
        if(e.target.value === ''){
            setErrorPasswordConfirm(false)
        }
        if (e.target.value !== password) {
            setErrorPasswordConfirm(true);
        } else {
            setErrorPasswordConfirm(false)
        }
        checkDisabled();
    }

    // Función de mandar contraseña
    const onSubmit = (e) => {
        e.preventDefault()
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/reset-password', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
                password: password,
                password_confirmation: passwordConfirm,
                token: token,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'App-Guard': 'customers'
            }
        })
          .then((res) => {
              if(res.ok){
                  modal.fire({
                      title: 'Contraseña actualizada.',
                      text: 'Tu nueva contraseña ha sido guardada. Puedes usarla para iniciar sesión.',
                      icon: 'success'
                  });
                  navigate('/iniciar-sesion');
              } else {
                  modal.fire({
                      title: 'Algo ha salido mal.',
                      text: 'Puede que el enlace haya caducado. Vuelve a solicitar otro enlace o inténtalo de nuevo en unos minutos.',
                      icon: 'error'
                  });
              }
          });
    }

    return (
      <div>
          <div className="reset-page">
              <div className="card card--semitransparent">
                  <div className="card-body">
                      <p className="fs-4 fw-bold mb-4">Guardar nueva contraseña</p>
                      <form className='add-form' onSubmit={onSubmit}>
                          <div className='form-group mb-3'>
                              <label htmlFor="email">Confirma tu correo electrónico</label>
                              <input
                                type="email"
                                id="email"
                                className="form-control"
                                value={email}
                                placeholder='Confirma el correo registrado'
                                onBlur={handleEmail}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errorEmail && (
                                <small className="d-block form-text text-danger">El correo no es válido.</small>
                              )}
                          </div>

                          <div className="form-group mb-3">
                              <label htmlFor="password">Nueva contraseña</label>
                              <div className="input-group">
                                  <input
                                    type={ showPassword ? 'text' : 'password' }
                                    id="password"
                                    className="form-control"
                                    value={password}
                                    placeholder="Escribe una contraseña"
                                    onBlur={handlePassword}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={togglePassword}>
                                      <Icon path={showPassword ? mdiEyeOff : mdiEye}
                                            size={1}
                                            horizontal
                                            vertical/>
                                  </button>
                              </div>
                              {errorPassword && (
                                <small className="d-block form-text text-danger">Contraseña no válida</small>
                              )}
                              <small className="d-block form-text text-muted">8 caracteres o más + 1 letra mayúscula + 1 letra minúscula + 1 número</small>
                          </div>

                          <div className='form-group mb-3'>
                              <label htmlFor="passwordConfirm">Confirma la nueva contraseña</label>
                              <input
                                type={ showPassword ? "text" : "password" }
                                id="passwordConfirm"
                                className="form-control"
                                placeholder="Repite la contraseña"
                                value={passwordConfirm}
                                onBlur={handlePasswordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                              />
                              {errorPasswordConfirm && (
                                <small className="form-text text-danger">Las contraseñas no coinciden</small>
                              )}
                          </div>

                          <div className="d-flex flex-column align-items-center">
                              <button
                                type="submit"
                                disabled={isDisabled}
                                className="btn btn-primary btn-lg">
                                  Guardar contraseña
                              </button>
                              {email && password && passwordConfirm && isDisabled &&
                              <p className="mt-2 mb-0 text-danger">El formulario no es válido. Comprueba que has rellenado todos los campos correctamente.</p>
                              }
                          </div>

                      </form>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default Reset


